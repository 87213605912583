import axios from "axios";
const apiUrl = "https://new-leeds-academy.ardillalabs.cloud/api";

export const sendMessagesToList = async (messageDetails) => {
    return new Promise(async (resolve, reject) => {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        };
  
        const body = JSON.stringify(messageDetails);
        const response = await axios.post(
          `${apiUrl}/message/bulk-message-sender`,
          body,
          config
        );

        if (response.data.success === true) {
          resolve(response.data);
        } else {
          reject(response.data);
        }
      } catch (err) {
        if (err.message === "Network Error") {
          reject({
            response: {
              data: {
                message: "Network Error",
                status: err.code,
                statusText: err.code,
              },
            },
          });
        } else {
          resolve(err.response.data);
        }
      }
    });
  }
  
export const sendMessagesToStudentList = async (messageDetails) => {
    return new Promise(async (resolve, reject) => {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        };
  
        const body = JSON.stringify(messageDetails);
        const response = await axios.post(
          `${apiUrl}/message/bulk-message-sender-for-all-student`,
          body,
          config
        );
        if (response.data.success === true) {
          resolve(response.data);
        } else {
          reject(response.data);
        }
      } catch (err) {
        if (err.message === "Network Error") {
          reject({
            response: {
              data: {
                message: "Network Error",
                status: err.code,
                statusText: err.code,
              },
            },
          });
        } else {
          resolve(err.response.data);
        }
      }
    });
  }

  export const sendMessagesToStudents = async (messageDetails) => {
    return new Promise(async (resolve, reject) => {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        };
  
        const body = JSON.stringify(messageDetails);
        const response = await axios.post(
          `${apiUrl}/message/bulk-message-sender-about-attendance`,
          body,
          config
        );
        if (response.data.status === true) {
          resolve(response.data);
        } else {
          reject(response.data);
        }
      } catch (err) {
        if (err.message === "Network Error") {
          reject({
            response: {
              data: {
                message: "Network Error",
                status: err.code,
                statusText: err.code,
              },
            },
          });
        } else {
          resolve(err.response.data);
        }
      }
    });
  }