import axios from "axios";
const apiUrl = "https://new-leeds-academy.ardillalabs.cloud/api"; 
const authUrl = "https://new-leeds-academy.ardillalabs.cloud/api/auth";
// Course Categories
export const getAllAdmins = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };

      const response = await axios.get(
        `${apiUrl}/users?skip=0&limit=10&role=admin`,
        config
      );
      if (response.status === 200) {
        resolve(response.data.data);
      } else {
        reject(response.data.data);
      }
    } catch (err) {
      if (err.message === "Network Error") {
        reject({
          response: {
            data: {
              message: "Network Error",
              status: err.code,
              statusText: err.code,
            },
          },
        });
      } else {
        reject(err);
      }
    }
  });
};

export const createAdmin = async (createAdminFormData) => {
  return new Promise(async (resolve, reject) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };

      const responseUser = await axios.post(
        `${authUrl}/create-admin`,
        createAdminFormData,
        config
      );

      if (responseUser.data.success === true) {
        resolve(responseUser.data);
      } else {
        reject(responseUser.data);
      }
    } catch (err) {
      if (err.message === "Network Error") {
        reject({
          response: {
            data: {
              message: "Network Error",
              status: err.code,
              statusText: err.code,
            },
          },
        });
      } else {
        resolve(err.response.data);
      }
    }
  });
};

export const editAdminPassword = async (editAdminFormData) => {
  return new Promise(async (resolve, reject) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };
      const response = await axios.put(
        `${authUrl}/create-new-password-for-teacher`,
        editAdminFormData,
        config
      );

      if (response.data.success === true) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    } catch (err) {
      if (err.message === "Network Error") {
        reject({
          response: {
            data: {
              message: "Network Error",
              status: err.code,
              statusText: err.code,
            },
          },
        });
      } else {
        reject(err);
      }
    }
  });
};