import axios from "axios";
const apiUrl = "https://new-leeds-academy.ardillalabs.cloud/api";

// Grades
export const getMessageService = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };

      const response = await axios.get(
        `${apiUrl}/message/get-message-service`,
        config
      );

      if (response.status === 200) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    } catch (err) {     
      if (err.message === "Network Error") {
        reject({
          response: {
            data: {
              message: "Network Error",
              status: err.code,
              statusText: err.code,
            },
          },
        });
      } else {
         reject({
            response: {
                message: err.response.data.message,
                status: err.response.status,
                statusText: err.code,
            },
          });
      }
    }
  });
};

export const addMessageService = async (addMessageServiceFormData) => {
  return new Promise(async (resolve, reject) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };
      const body = JSON.stringify({
        user_id: addMessageServiceFormData.userId,
        api_key: addMessageServiceFormData.apiKey,
        sender_id: addMessageServiceFormData.senderId,
        status: addMessageServiceFormData.status,
        attendance: addMessageServiceFormData.attendance,
        class_fee_to_parents: addMessageServiceFormData.class_fee_to_parents,
        class_fee_to_teachers: addMessageServiceFormData.class_fee_to_teachers
      });
      const response = await axios.post(
        `${apiUrl}/message/create-message-service`,
        body,
        config
      );

      if (response.data) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    } catch (err) {
      if (err.message === "Network Error") {
        reject({
          response: {
            data: {
              message: "Network Error",
              status: err.code,
              statusText: err.code,
            },
          },
        });
      } else {
        reject({
            response: {
                message: err.message,
                status: err.response.status,
                statusText: err.code,
            },
          });
      }
    }
  });
};

export const editMessageService = async (editMessageServiceFormData) => {
  return new Promise(async (resolve, reject) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };

      const body = JSON.stringify({
        "status": editMessageServiceFormData
      });
      
      const response = await axios.post(
        `${apiUrl}/message/create-message-service`,
        body,
        config
      );

      if (response.data.success === true) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    } catch (err) {
      if (err.message === "Network Error") {
        reject({
          response: {
            data: {
              message: "Network Error",
              status: err.code,
              statusText: err.code,
            },
          },
        });
      } else {
        reject({
            response: {
                message: err.message,
                status: err.response.status,
                statusText: err.code,
            },
          });
      }
    }
  });
};